import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { empty, Observable } from 'rxjs';
import { EmployeeService } from "@hr-core";
import { EmployeeBasicInfo } from "models";

@Injectable({
    providedIn: "root"
})
export class EmployeeDetailsResolver implements Resolve<any> {

    constructor(private employeeService: EmployeeService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<EmployeeBasicInfo> {
        const id = route.paramMap.get('id');
        if (id) {
            return this.employeeService.basicInfo(+id).pipe(
                catchError(() => {
                    return empty();
                })
            );
        }
        return empty();
    }
}
