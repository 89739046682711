import { Component, OnInit } from '@angular/core';
import { RoleService } from 'projects/common/src/lib';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

	constructor(
        private roleService: RoleService
	) { }

	ngOnInit(): void {
	}

}
