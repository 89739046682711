import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ValidateComponent } from './components/validate/validate.component';
import { ModuleLoadGuard } from 'projects/common/src/public-api';
import { EmployeeDetailsResolver } from './feature/dashboard/resolvers/employee-details.resolver';

const routes: Routes = [{
    path: 'validate',
    component: ValidateComponent
}, {
    path: 'profile',
    loadChildren: () => import('./feature/dashboard/dashboard.module').then(m => m.EmployeeDashboardModule),
    canLoad: [ModuleLoadGuard],
    resolve: {
        employeeData: EmployeeDetailsResolver,
    },
}, {
    path: '',
    redirectTo: 'profile',
    pathMatch: 'full'
}, {
    path: '**',
    redirectTo: 'profile'
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
