import { Component, OnInit } from '@angular/core';
import { AppService, RoleService } from 'projects/common/src/public-api';
import { Router } from '@angular/router';

@Component({
    template: '',
})
export class ValidateComponent implements OnInit {

    constructor(
        private router: Router,
        private appService: AppService,
        private roleService: RoleService
    ) { }


    ngOnInit() {
        this.getToken();
        this.getColumnConfig();
    }

    private async getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token') ?? sessionStorage.getItem('token');
        if (token) {
            window.sessionStorage.setItem('token', token);
            window.sessionStorage.setItem('orgCode', urlParams.get('code'));
            window.sessionStorage.setItem('employeeId', urlParams.get('employeeId'));
            window.sessionStorage.setItem('employeeName', urlParams.get('employeeName'));
            window.sessionStorage.setItem('school', urlParams.get('schoolName'));
            window.sessionStorage.setItem('userName', urlParams.get('userName'));
            setTimeout(() => {
                this.router.navigateByUrl('/', { preserveQueryParams: false });
            }, 900);
        }
    }

    private getColumnConfig() {
        this.appService.datatableSetup('HR');
    }
}
