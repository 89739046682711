import { Component, Output, EventEmitter } from '@angular/core';
import { Apps } from 'models';
import { RoleService, AppService, UserAuthService } from 'projects/common/src/lib';
@Component({
    selector: 'header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['header-bar.component.scss']
})

export class HeaderBarComponent {
    @Output() openSlider: EventEmitter<void> = new EventEmitter();
    @Output() openSetting: EventEmitter<void> = new EventEmitter();

    apps: Apps[];
    employeeName: string;
    image: any;

    constructor(
        private appService: AppService,
        private roleService: RoleService,
        private userService: UserAuthService
    ) { }

    ngOnInit() {
        this.getAppData();
        this.employeeName = this.userService.getEmployeeName();
        // this.getImage(this.userService.getEmployeeId());
        // this._store.employeeData.subscribe((employee) => {
        //     if (employee) {
        //         this.employeeName = `${employee.firstName} ${employee.lastName}`;
        //     }
        // });
    }

    // getImage(employeeProfileId) {
    //     const params = {
    //         module: 'Employees',
    //         fileName: employeeProfileId,
    //         // contentType: 'image/jpeg'
    //     }
    //     this.uploadService.getDocument(params).subscribe((data) => {
    //         if(data.status != 406) {
    //             this.image = data ? data.URL : Images.user;
    //         } else {
    //             this.image = Images.user;
    //         }
    //     }, err => {
    //         this.image = Images.user;
    //     });
    // }

    private getAppData() {
        this.appService.get().subscribe(result => {
            this.apps = result;
        })
    }
}
